import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { SupportService } from '../../services/platformcours/services/support.service';
import * as _ from 'lodash';
import { SearchFilterPipe } from '../pipe/search-filter.pipe';

@Component({
  selector: 'app-association-support-prom',
  templateUrl: './association-support-prom.component.html',
  styleUrls: ['./association-support-prom.component.scss']
})
export class AssociationSupportPromComponent implements OnInit, OnDestroy {
  @Input() template;
  @Input() support;

  @Output() onSupportChange = new EventEmitter();

  public allSupport: any;
  public completeSupportList: any;
  public allSupport$ : Subscription;
  public allSupportDispo : any = [];
  public filterTitle = null;


  constructor(
    private Sup: SupportService,
    private SearchPipe: SearchFilterPipe
  ) { }

  ngOnInit(): void {
    this.allSupport$ = this.Sup.getAllFullSupport().subscribe((res: any) => {
      this.completeSupportList = res ? res : [];
      if(this.template == 'cours') {
        this.allSupport = res ? res.allCours : [];
        this.alreadySet();
      } else if(this.template == 'exo') {
        this.allSupport = res ? res.allExos : [];
        this.alreadySet();
      } else if(this.template == 'corrige') {
        this.allSupport = res ? res.allCorrections : [];
        this.alreadySet();
      } else {
        console.log('Unknown template');
        this.allSupport = [];
      }
    });
  }

  ngOnDestroy(): void {
    this.allSupport$.unsubscribe();
  }

  /**
   * Method to set only not selected support in list
   */
  alreadySet() {
    this.allSupport.forEach(elt => {
      let index = _.findIndex(this.support, (o:any) => {
        return o == elt._id;
      });
      if(index == -1) this.allSupportDispo.push(elt);
    });
  }

  /**
   * Method to filterData with input filter using PipeTransform
   */
  filterData() {
    this.allSupportDispo = this.SearchPipe.transform(this.allSupportDispo, this.filterTitle, ['title']);
  }

  /**
   * Method to add new support to prom with emit event
   * @param id - id's support
   */
  addToSupport(id : string) {
    this.support.push(id);

    let index = _.findIndex(this.allSupportDispo, (o:any) => {
      return o._id == id;
    });
    this.allSupportDispo.splice(index, 1);
    this.allSupportDispo = [...this.allSupportDispo];

    this.onSupportChange.emit({template: this.template, support: this.support});
  }

  /**
   * Method to remove support to prom with emit event
   * @param j - index in support already set
   */
  removeFromSupport(j : number) {
    switch (this.template) {
      case 'cours': {
        let tmp = _.findIndex(this.completeSupportList.allCours, (o: any) => {
          return o._id == this.support[j];
        });
        this.allSupportDispo.push(this.completeSupportList.allCours[tmp]);
        this.support.splice(j, 1);
        this.onSupportChange.emit({template: this.template, support: this.support});
        break;
      }
      case 'exo': {
        let tmp = _.findIndex(this.completeSupportList.allExos, (o: any) => {
          return o._id == this.support[j];
        });
        this.allSupportDispo.push(this.completeSupportList.allExos[tmp]);
        this.support.splice(j, 1);
        this.onSupportChange.emit({template: this.template, support: this.support});
        break;
      }
      case 'corrige': {
        let tmp = _.findIndex(this.completeSupportList.allCorrections, (o: any) => {
          return o._id == this.support[j];
        });
        this.allSupportDispo.push(this.completeSupportList.allCorrections[tmp]);
        this.support.splice(j, 1);
        this.onSupportChange.emit({template: this.template, support: this.support});
        break;
      }
      default:
        console.log('Unknown template');
        break;
    }
  }
}

interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const rightBarItems: NavData[] = [

];

export const navItems: NavData[] = [
  {
    name: 'Home',
    url: 'home',
    icon: 'icon-home',
  },  {
    title: true,
    name: 'SuperAdmin'
  }, {
    name: 'SmartGnome',
    icon: 'icon-settings',
    url: ' ',
    children: [
      {
        name: 'Home',
        url: 'smartgnome/home',
        icon: 'icon-home'
      },
      {
        name: 'Data',
        url: 'smartgnome/data',
        icon: 'icon-cloud-upload'
      },
      {
        name: 'Device',
        url: 'smartgnome/device',
        icon: 'icon-screen-smartphone'
      },
      {
        name: 'Sensor',
        url: 'smartgnome/sensor',
        icon: 'icon-chemistry'
      },
      {
        name: 'Type Device',
        url: 'smartgnome/type-device',
        icon: 'icon-docs'
      },
      {
        name: 'Profil',
        url: 'smartgnome/profil',
        icon: 'icon-people'
      },
      {
        name: 'Warning',
        url: 'smartgnome/warning',
        icon: 'icon-bell'
      },
    ]
  }, {
    name: 'Plateforme de Cours',
    icon: 'icon-settings',
    url: ' ',
    children: [
      {
        name: 'Home',
        url: 'platform/home',
        icon: 'icon-home'
      },
      {
        name: 'Profil',
        url: 'platform/profil',
        icon: 'icon-people'
      },
      {
        name: 'Promotion',
        url: 'platform/promotion',
        icon: 'icon-graduation'
      },
      {
        name: 'Matière',
        url: 'platform/subject',
        icon: 'icon-notebook'
      },
      {
        name: 'Cours',
        url: 'platform/cours',
        icon: 'icon-screen-desktop'
      },
      {
        name: 'Exercices',
        url: 'platform/exo',
        icon: 'icon-chemistry'
      },
      {
        name: 'Corrige',
        url: 'platform/corrige',
        icon: 'icon-magic-wand'
      },
      {
        name: 'Diaporama',
        url: 'platform/diaporama',
        icon: 'icon-docs'
      },
      {
        name: 'Pedagogie',
        url: 'platform/pedagogie',
        icon: 'icon-magnet'
      },
      {
        name: 'Communication',
        icon: 'icon-bell',
        url: 'platform/communication',
        children: [
          {
            name: 'Annonces',
            url: 'platform/communication/advert',
            icon: 'icon-pin'
          }, {
            name: 'Calendrier',
            url: 'platform/communication/event',
            icon: 'icon-calendar'
          }, {
            name: 'News',
            url: 'platform/communication/news',
            icon: 'icon-feed'
          }
        ]
      }
    ]
  }, {
    name: 'Sikara',
    url: 'https://www.sikara.fr',
    icon: 'icon-doc',
    class: 'mt-auto',
    variant: 'success',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];

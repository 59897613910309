import { Component, OnInit, Inject, Input } from '@angular/core';
import { navItems } from '../_nav';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leftbar',
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss']
})
export class LeftbarComponent implements OnInit {
  @Input() user: any = {};

  public navItems = navItems;
  public sidebarMinimized = true;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { _Profil } from '../model/_profil.model';

const endpointProfil = '/api/pc/profil';
const httpOptions = {
  headers: new HttpHeaders({
  'Content-Type': 'application/json'
})
};

@Injectable({
  providedIn: 'root'
})
export class ProfilService {

  constructor(
    private http : HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Get all profil
   * @return {Array}
   */
  getAllProfil() {
    return this.http.get<_Profil[]>(`${endpointProfil}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get a profil
   * @param {String} id - profil's id
   * @return {_Profil}
   */
  getProfil(id: string) {
    return this.http.get<_Profil>(`${endpointProfil}/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  
  /**
   * Add profil
   * @param {_Profil} profil
   */
  addProfil(profil: _Profil) {
    return this.http.post<_Profil>(`${endpointProfil}`, profil, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Update profil
   * @param {_Profil} profil
   */
  updateProfil(profil) {
    Reflect.deleteProperty(profil, '__v');
    return this.http.put<_Profil>(`${endpointProfil}/${profil._id}`, profil, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Delete profil
   * @param {String} id - profils Id
   */
  deleteProfil(id: String) {
    return this.http.delete<_Profil>(`${endpointProfil}/${id}`, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}

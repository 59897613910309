import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { _Profil } from '../../services/platformcours/model/_index.model';
import * as _ from 'lodash';
import { ProfilService } from '../../services/platformcours/services/profil.service';

@Component({
  selector: 'app-search-add',
  templateUrl: './search-add.component.html',
  styleUrls: ['./search-add.component.scss']
})
export class SearchAddComponent implements OnInit, OnChanges {
  @Input() name;
  @Input() data;
  @Input() template;
  @Input() reset;

  @Output() onMemberChange = new EventEmitter();

  public searchText: String = '';
  public selected: Array<_Profil> = [];
  public send: string[] = [];
  public list: Array<_Profil> = [];


  /* -- exist -- */
  public unselected: Array<_Profil> = [];

  constructor(
    private Pfl: ProfilService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.reset && changes.reset.currentValue == true) {
      this.Pfl.getAllProfil().subscribe(res => {
        this.list = res;
        this.selected.forEach(val => { this.list.push(val)});
        this.selected = [];
        this.send = [];
        setTimeout(() => {
          this.onMemberChange.emit(this.send);
        }, 1000);
      });
    }

    if(changes.template && changes.template.currentValue == 'eleves') {
      this.Pfl.getAllProfil().subscribe(res => {
        this.list = res;
      });
    }

    if(changes.template && changes.template.currentValue == 'exist') {
      this.alreadySet();
    }

    if(changes.data && changes.data.previousValue && changes.data.currentValue.prom != changes.data.previousValue.prom) {
      this.alreadySet();
    }
  }

  onChangeArray(index: any, selected: any) {
    let existSelected = _.findIndex(selected, (o) => { return o._id == index});
    let existPpl = _.findIndex(this.list, (o) => {return o._id == index});

    if(existSelected == -1) {
      let tmp = _.find(this.list, (o) => {
        return o._id == index;
      });
      selected.push(tmp);
      this.list.splice(existPpl, 1);
      this.send.push(tmp._id);
    } else {
      let exist = _.findIndex(this.send, (o) => {return o == index});
      let tmp = _.find(selected, (o) => {
        return o._id == index;
      });
      selected.splice(existSelected, 1);
      this.list.push(tmp);
      this.send.splice(exist, 1);
    }
    this.onMemberChange.emit(this.send);
  }

  alreadySet() {
    this.Pfl.getAllProfil().subscribe(res => {
      this.list = res;
      this.selected = [];
      this.send = [];
      this.data.selected.forEach(elv => {
        let index = _.findIndex(this.list, (o) => {return o._id == elv});
        this.selected.push(this.list[index]);
        this.list.splice(index, 1);
      });
      this.unselected =this.list;
      this.send = this.data.selected;
    });
  }

  resetList() {
    this.selected.forEach(elt => {
      this.list.push(elt);
    });
    this.selected = [];
    this.onMemberChange.emit([]);
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const endpoint = '/api/log';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export class _Log {
  public static fromJson(json: Object): _Log {
    return new _Log(
      json['type'],
      json['msg'],
      json['publisht_date'],
      json['model'],
      json['on'],
      json['by'],
      json['code']
    );
  }

  constructor(
    public _id?: String,
    public type: String = '',
    public msg: String = '',
    public publisht_date: Number = Date.now(),
    public model: String = '',
    public on: String = '',
    public by: String = '',
    public code: Number = 0
  ){}
}

@Injectable({
  providedIn: 'root'
})
export class PrivateLogsService {

  constructor(
    private http: HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

  /**
   * GET All log
   * @return {Array}
   */
  getAllLog() {
    return this.http.get<_Log[]>(`${endpoint}`).pipe(
      catchError(this.handleError)
    );
  }
}

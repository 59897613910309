import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { _Diapo } from '../../services/platformcours/model/_diapo.model';
import * as _ from 'lodash';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap';
defineLocale('fr', frLocale);

@Component({
  selector: 'app-gallery-diapo',
  templateUrl: './gallery-diapo.component.html',
  styleUrls: ['./gallery-diapo.component.scss']
})
export class GalleryDiapoComponent implements OnInit, OnChanges {
  @Input() fdiapo;
  @Input() selection;

  @Output() onDiapoSelect = new EventEmitter();

  public diapos: Array<_Diapo> = [];
  public paginationConfig = {
    itemsPerPage: 8,
    currentPage: 1
  };
  public myIndex = 0;
  public selectedDiapo = [];

  public filterPos = null;
  public filterDate = null;
  public filterTitle = null;

  public bsConfig;
  public maxDate;


  constructor(
    bsLocaleService : BsLocaleService
  ) {
    bsLocaleService.use('fr');
    this.maxDate = new Date();
  }

  ngOnInit() {
    this.bsConfig = {
      adaptivePosition: true,
      containerClass: 'theme-green',
      locale: 'fr'
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.fdiapo ) {
      this.diapos = this.changeLength(this.fdiapo, 500);
      this.paginationConfig.currentPage = 1;
    }

    if(changes.selection) {
      this.selectedDiapo = _.clone(changes.selection.currentValue);
      this.selectedDiapo = _.remove(this.selectedDiapo, (n) => {
        return n != null;
      });

    }
  }

  onPageChange(number: number) {
    this.paginationConfig.currentPage = number;
    this.myIndex = ((this.paginationConfig.currentPage - 1) * this.paginationConfig.itemsPerPage);
  }

  changeLength(data: Array<_Diapo>, size: number) : Array<any> {
    data.forEach(elt => {
      elt.content = elt.content.substring(0, size);
    });
    return data;
  }

  selectDiapo(numberDiapo: number, index: number) : void {
    document.getElementsByClassName('thumbDiapo')[numberDiapo].classList.toggle('thumbDiapoSelected');
    this.onDiapoSelect.emit(index);
  }

  isSelected(id: string): boolean {
    let index = _.findIndex(this.selectedDiapo, (o) => {
      return o._id == id;
    });
    return index != -1;
  }

  resetFilter() {
    this.filterDate = null;
    this.filterPos = null;
    this.filterTitle = null;
  }
}

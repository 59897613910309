import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})

export class SearchFilterPipe implements PipeTransform {

  transform(list: any[], searchText: String, filters: string[]): any {
    if(!list) return [];
    if(!searchText || searchText == '') return list;

    searchText = searchText.toLowerCase();

    let filteredItems = [];

    list.forEach(item => {
      filters.forEach(filter => {
        if(item[filter] != null && item[filter].toLowerCase().includes(searchText)) {
          filteredItems.push(item);
        }
      });
    });
    return filteredItems;
  }
}

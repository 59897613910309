import { Component, OnDestroy, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../_nav';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { PrivateLogsService, _Log } from '../../services/logs/logs.service';

@Component({
  selector: 'app-rightbar',
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.scss']
})
export class RightbarComponent implements OnInit {
  @Input() user: any = {};
  public optionLogged: any = false;

  public navItems = navItems;
  public sidebarMinimized = true;
  public logs: Array<_Log> = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private log: PrivateLogsService
  ) {}


  ngOnInit() {
    if(this.user) {
      this.log.getAllLog().subscribe(res => {
        this.logs = res ? res : [];
      });
    }
  }

  ngOnDestroy(): void {
    // this.rightBar.disconnect();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'supportFilter'
})
export class SupportFilterPipe implements PipeTransform {

  transform(value: any, list: any[]): any {
    if(!value) return null;
    let data = _.find(list, (o)=> {
      return o._id == value
    });
    if(data) return data.title;
  }

}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { _Subject } from '../model/_subject.model';

const endpointSubject = '/api/pc/subject';
const httpOptions = {
  headers: new HttpHeaders({
  'Content-Type': 'application/json'
})
};

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor(
    private http : HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Get All Subject
   * @return {Array}
   */
  getAllSubject() {
    return this.http.get<_Subject[]>(`${endpointSubject}`).pipe(
      catchError(this.handleError)
    )
  }
  
  /**
   * Get one Subject
   * @param {String} id
   * @return {_Subject}
   */
  getSubject(id: String) {
    return this.http.get<_Subject>(`${endpointSubject}/${id}`).pipe(
      catchError(this.handleError)
    )
  }
  
  /**
   * Create a new Subject
   * @param {_Subject} subject
   */
  addSubject(subject: _Subject): Observable<_Subject> {
    return this.http.post<_Subject>(`${endpointSubject}`, subject, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Update a subject
   * @param {_Subject} subject
   */
  updateSubject(subject: _Subject) {
    Reflect.deleteProperty(subject, '__v');
    return this.http.put<_Subject>(`${endpointSubject}/${subject._id}`, subject, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  
  /**
   * Delete a subject
   * @param {String} id - Subject's id
   */
  deleteSubject(id: String) {
    return this.http.delete<_Subject>(`${endpointSubject}/${id}`, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

}

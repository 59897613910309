import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone, SimpleChanges } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { SmartgnomeService } from '../../services/smartgnome/smartgnome.service';

export class Map {
  constructor(
    public latitude: Number = null,
    public longitude: Number = null,
    public elevation: Number = null,
    public address: String = '',
  ) {}
}


@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss']
})
export class GmapComponent implements OnInit, OnChanges {
  public map: any;
  public searchControl: FormControl;

  @Input() template: any = '';
  @Output() gmapEvent = new EventEmitter();
  @ViewChild("search") public searchElementRef: ElementRef;

  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;
  @Input() marker: any;

  @Input() reset: boolean = false;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private sg: SmartgnomeService
  ) { }

  ngOnInit() {
    this.map = new Map();
    this.map.latitude = this.latitude ? this.latitude : 46.139033;
    this.map.longitude = this.longitude ? this.longitude : -2.4351197;
    this.map.zoom = this.zoom ? this.zoom : 5;

    this.searchControl = new FormControl();

    if(this.template =='input') {
      this.mapsAPILoader.load().then(()=> {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ['address']
        });
  
        let elevator = new google.maps.ElevationService;
  
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            this.map.longitude = place.geometry.location.lng()
            this.map.latitude = place.geometry.location.lat()
            elevator.getElevationForLocations({locations: [place.geometry.location]}, (results) => {
              if(results[0]) this.map.elevation = results[0].elevation;
              this.map.address = place.formatted_address;
              this.gmapEvent.emit(this.map);
            });
          });
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.latitude && changes.longitude && this.map) {
      this.map.setCenter({lat: changes.latitude.currentValue, lng: changes.longitude.currentValue})
    }

    if(changes.reset && changes.reset.currentValue == false) {
      this.searchControl = new FormControl();
    }
  }

  protected mapReady(map: any) {
    this.map = map;
  }
}

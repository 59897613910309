import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const endpointSupport = '/api/pc/support';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private http : HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Get all Support Full de chez Full
   * @return {Array}
   */
  getAllFullSupport() {
    return this.http.get<any[]>(`${endpointSupport}/full`).pipe(
      catchError(this.handleError)
    );
  }
  
  /**
   * Get All Support with no diapo details
   * @return {Array}
   */
  getAllSupport() {
    return this.http.get<any[]>(`${endpointSupport}/`).pipe(
      catchError(this.handleError)
    );
  }
}

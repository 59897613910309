import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import {AuthService} from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.component.scss']
})
export class LoginComponent implements OnInit {
  public error: boolean = false;

  constructor(private authService: AuthService, private router: Router) { }

  email: string = '';
  password: string = '';

  ngOnInit() {
    let storeUser = JSON.parse(localStorage.getItem('StoredUser'));
    if(storeUser) this.router.navigate(['/home']);
  }

  login(): void {
    if(this.email && this.password) {
      this.authService.login(this.email, this.password)
      .subscribe(data => {
        this.error = false;
        // localStorage.setItem('StoredUser', JSON.stringify(data));
        this.router.navigate(['/home']);
      });
    } else {
      this.error = true;
    }
  }

}

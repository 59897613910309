import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Pipe({
  name: 'filterMember'
})
export class FilterMemberPipe implements PipeTransform {

  transform(value: string, list: any[], filter: string): Observable<any> {
    if(!value) return null;
    return Observable.create(obs => {
      let data =  _.find(list, (o: any) => {
        return o._id == value
      });
      if(!filter) return data;
      if(data) {
        obs.next(data[filter]);
        obs.complete();
      }
    });
  }

}

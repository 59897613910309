import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export interface mapObject {
  latitude: Number,
  longitude: Number,
  zoom?: Number,
  elevation?: Number,
  address?: String
};

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private http: HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Upload file
   * @param {FileList} file 
   */
  uploadFile(file: FileList) {
    let formData = new FormData();
    formData.append('pdf', file[0], file[0].name);
    return this.http.post<any>('/api/upload/pdf', formData).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Function for quill Editor
   */
  EditorCreated(editor){
    let that = this
    /**
   * Step1. select local image
   *
   */
    function selectLocalImage() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('multiple', 'multiple');
      input.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/x-icon');
      input.click();

      // Listen upload local image and save to server
      input.onchange = () => {
        for (let i = 0; i < input.files.length; i++) {
          const file = input.files[i];
          // file type is only image.
          if (/^image\//.test(file.type)) {
            saveToServer(file);
          } else {
            console.warn('You could only upload images.');
          }
        }
      };
    }

    /**
     * Step2. save to server
     *
     * @param {File} file
     */
    function saveToServer(files: File) {
        let formData = new FormData();
        formData.append('pathName', 'forage');
        formData.append('file', files);
        that.http.post<any>('/api/upload/', formData)
        .subscribe(event=>{
          insertToEditor(event.imageUrl)
        })
    }

    /**
     * Step3. insert image url to rich editor.
     *
     * @param {string} url
     */
    function insertToEditor(url: string) {
      // push image url to rich editor.
      const range = editor.getSelection();
      editor.insertEmbed(range.index, 'image', url);
    }

    editor.getModule('toolbar').addHandler('image', () => {
      selectLocalImage();
    });
  }


  /* ----------------------- */
  /* ----------MAP---------- */
  /* ----------------------- */

  /**
   * Set object with properties needed by agm using interface
   * @param {Number} lat - latitude
   * @param {Number} long - longitude
   * @param {Number} elevation - altitude
   * @param {Number} zoom - zomm
   * @param {String} address - addresse
   */
  setMap(lat: Number, long:Number, zoom?:Number, elevation?: Number, address?: String): mapObject {
    let tmp: mapObject = {
      latitude: lat,
      longitude: long
    };
    if(zoom) tmp.zoom = zoom;
    if(address) tmp.address = address;
    if(elevation) tmp.elevation = elevation;

    return tmp;
  }
  

  /* ----------------------- */
  /* ------DismissAlert----- */
  /* ----------------------- */
  /**
   * Set Alert Message with a timeout
   * @param {Array} tab - tab where we push the alert
   * @param {String} typeAlert - type of alert
   * @param {String} msgInBold - msg in bold
   * @param {String} msg1 - msg part 1
   * @param {String} msg2 - msg part 2
   * @param {Number} timeoutSetted - timeout before disappear
   */
  setAlertDismiss(tab, typeAlert, msgInBold, msg1, msg2, timeoutSetted) {
    tab.push({
      type: typeAlert,
      msgBold: msgInBold,
      msg: {
        partie1: msg1,
        partie2: msg2
      },
      timeout: timeoutSetted
    })
  }



  /* ------------------------*/
  /* -------Diapo Form-------*/
  /* ------------------------*/

  /**
   * Switch position diapo in position input
   * @param {number} inputPosition - info to add in input
   * @param {number} diapoPosition - info save in BdD default
   */
  onDiaposChange(inputPosition: any, diapoPosition: any) {
    inputPosition = diapoPosition;
  }

  /**
   * Add to tab Cours and upgrade size
   * @param {Array} tab - tab contain object
   * @param {number} position - position in tab
   * @param {Object} diapo - diapo to add
   * @return {number} size of diaporam to modify
   */
  addToTabCours(tab: any, position: number, diapo: any): number {
    tab[position] = diapo;
    for (let index = 0; index < tab.length; index++) {
      let tmp = null;
      if(!tab[index]) tab[index] = tmp;
    }
    return tab.length * 162;
  }

  /**
   * Set diapos id 
   * @param {Array} tab - tab contain object
   * @param {Array} diaposTab - tab id only
   */
  setDiapos(tab: any, diaposTab: any) {
    tab.forEach(elt => {
      let tmp = elt ? elt._id : null;
      diaposTab.push(tmp)
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { _Promotion } from '../model/_promotion.model';
const endpointPromotion = '/api/pc/promotion';
const httpOptions = {
  headers: new HttpHeaders({
  'Content-Type': 'application/json'
})
};

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(
    private http: HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

    /**
   * Get All promotion Full
   * @return {Array}
   */
     getAllFullProm() {
      return this.http.get<any>(`${endpointPromotion}/fullall`).pipe(
        catchError(this.handleError)
      )
    }
  
    /**
     * Get one full promotion
     * @param {String} id - promotion's Id
     */
    getFullProm(id: String) {
      return this.http.get<any>(`${endpointPromotion}/full/${id}`).pipe(
        catchError(this.handleError)
      )
    }
  
    /**
     * Get all promotion
     * @return {Array}
     */
    getAllProm() {
      return this.http.get<_Promotion[]>(`${endpointPromotion}`).pipe(
        catchError(this.handleError)
      )
    }
  
    /**
     * Get one Promotion
     * @param {String} id - promotion's Id
     */
    getProm(id: String) {
      return this.http.get<_Promotion>(`${endpointPromotion}/${id}`).pipe(
        catchError(this.handleError)
      );
    }
  
    /**
     * Get one promotion by organism
     * @param {String} organism
     */
    getByOrg(organism: String) {
      return this.http.get<any>(`${endpointPromotion}/org/${organism}`).pipe(
        catchError(this.handleError)
      );
    }
  
    /**
     * Create a new Prom
     * @param {_Promotion} prom
     */
    addProm(prom: _Promotion): Observable<_Promotion> {
      return this.http.post<_Promotion>(`${endpointPromotion}`, prom, httpOptions).pipe(
        catchError(this.handleError)
      )
    }
  
    /**
     * Modify a prom
     * @param {_Promotion} prom
     */
    updateProm(prom: _Promotion) {
      Reflect.deleteProperty(prom, '__v');
      return this.http.put<_Promotion>(`${endpointPromotion}/${prom._id}`, prom, httpOptions).pipe(
        catchError(this.handleError)
      );
    }
  
    /**
     * Modify member of prom
     * @param {_Promotion} prom
     */
    updatePromMember(prom: _Promotion) {
      Reflect.deleteProperty(prom, '__v');
      return this.http.put<_Promotion>(`${endpointPromotion}/member/${prom._id}`, prom, httpOptions).pipe(
        catchError(this.handleError)
      );
    }
  
    /**
     * Delete a prom
     * @param {String} id - promotion's Id
     */
    deleteProm(id: String) {
      return this.http.delete<_Promotion>(`${endpointPromotion}/${id}`, httpOptions).pipe(
        catchError(this.handleError)
      );
    }
}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SmartgnomeService, _Sensor } from '../../../services/smartgnome/smartgnome.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.scss']
})
export class SensorListComponent implements OnInit, OnChanges {
  @Input() template: any;
  @Input() reset : any;
  @Input() invalid: boolean = false;
  @Input() dataInit: any;
  @Output() newSensorEvent = new EventEmitter();

  public sensors: _Sensor[] = [];
  public sensorsActivate: any = [];
  public tempInvalid: boolean = false;

  constructor(
    private sg: SmartgnomeService
  ) { }

  ngOnInit() {
    this.sg.getAllSensor().subscribe(res => {
      this.sensors = res;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.reset && changes.reset.currentValue == true) {
      this.sensorsActivate = [];
      setTimeout(() => {
        this.newSensorEvent.emit(this.sensorsActivate);
      }, 1000);
    }
    this.tempInvalid = changes.invalid && changes.invalid.currentValue == true ? true : false;

    if(changes.dataInit && changes.dataInit.currentValue.length > 0) {
      this.sensorsActivate = [];
      this.dataInit.forEach(elt => {
        let tmp = _.find(this.sensors, (o: any) => { return o._id == elt});
        if(this.template == 'Modif') {
          this.sensorsActivate.push(tmp._id);
          // console.log(this.sensorsActivate)
        } else if(this.template == 'Supr') {
          this.sensorsActivate.push(tmp);
        } else {
          console.log('Unknown template');
        }
        this.sensorsActivate = _.uniq(this.sensorsActivate);
      });
    }
  }

  isChecked(sensorId) {
    let index = _.findIndex(this.sensorsActivate, (o:any) => {
      return o == sensorId;
    });
    return index != -1;
  }

  onStateChange(sensorId: String) {
    let index = _.findIndex(this.sensorsActivate, (o:any) => {
      return o == sensorId;
    });
    if(index == -1) {
      this.sensorsActivate.push(sensorId);
    } else {
      this.sensorsActivate.splice(index, 1);
    }
    this.newSensorEvent.emit(this.sensorsActivate);
  }
}

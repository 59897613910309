import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertModule, AlertConfig } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AgmCoreModule } from '@agm/core';
import { GmapComponent } from './gmap/gmap.component';
import { SensorListComponent } from '../smartgnome/components/sensor-list/sensor-list.component';
import { CharacteristicListComponent } from '../smartgnome/components/characteristic-list/characteristic-list.component';
import { ModalManagerComponent } from './modal-manager/modal-manager.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;


import {
  MatToolbarModule,
  MatMenuModule,
  MatTabsModule,
  MatDividerModule,
  MatCardModule,
  MatListModule,
  MatExpansionModule,
  MatButtonModule,
  MatIconModule,
  MatDialogModule,
  MatInputModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatTreeModule,
  MatProgressBarModule,
  MatFormFieldModule,
  MatSelectModule,
} from '@angular/material';
import { SearchAddComponent } from './search-add/search-add.component';
import { SearchFilterPipe } from './pipe/search-filter.pipe';
import { FilterPromPipe } from './pipe/filter-prom.pipe';
import { ReversePipe } from './pipe/reverse.pipe';
import { SupportFilterPipe } from './pipe/support-filter.pipe';

import { QuillModule } from 'ngx-quill';
import { TimepickerModule } from 'ngx-bootstrap';
import { FilterMemberPipe } from './pipe/filter-member.pipe';
import { GalleryDiapoComponent } from './gallery-diapo/gallery-diapo.component';
import { FilterDiapoPipe } from './pipe/filter-diapo.pipe';
import { AssociationSupportPromComponent } from './association-support-prom/association-support-prom.component';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    MatDividerModule,
    MatCardModule,
    MatListModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    FlexLayoutModule,
    TabsModule,
    NgxPaginationModule,
    AlertModule,
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCEGCG98d_nObG2LQkKTNI_7L2LIpCo7rY',
      libraries: ['places', 'elevation']
    }),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    QuillModule,
  ],
  exports: [
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    MatDividerModule,
    MatCardModule,
    MatListModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    FlexLayoutModule,
    TabsModule,
    NgxPaginationModule,
    AlertModule,
    ModalModule,
    GmapComponent,
    ModalManagerComponent,
    BsDatepickerModule,
    SensorListComponent,
    CharacteristicListComponent,
    SearchAddComponent,
    SearchFilterPipe,
    FilterPromPipe,
    ReversePipe,
    SupportFilterPipe,
    QuillModule,
    TimepickerModule,
    FilterMemberPipe,
    GalleryDiapoComponent,
    AssociationSupportPromComponent
  ],
  declarations: [
    GmapComponent,
    ModalManagerComponent,
    SensorListComponent,
    CharacteristicListComponent,
    SearchAddComponent,
    SearchFilterPipe,
    FilterPromPipe,
    ReversePipe,
    SupportFilterPipe,
    FilterMemberPipe,
    GalleryDiapoComponent,
    FilterDiapoPipe,
    AssociationSupportPromComponent,
  ],
  providers: [
    { provide: AlertConfig, useFactory: getAlertConfig }, 
    SearchFilterPipe,
    FilterDiapoPipe
  ]
})
export class SharedModule { 
}


export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: 'success' });
}


export const QuillConfigGlobal: any = {
  toolbar : {
    syntax: true,
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [{ 'script': 'super' }, { 'script': 'sub' }],
      [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
      [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
      [ 'direction', { 'align': [] }],
      [ 'link', 'image']
    ],
    imageResize: true
  },
  style : {
    height: '600px',
    overflow: 'auto'
  }
}


export const theme: any = ['HTML', 'CSS', 'SCSS', 'Javascript', 'Angular', 'JQuery', 'CMS', 'Wordpress', 'Programmation', 'OS', 'PHP', 'NodeJS', 'VueJS', 'Virtualisation', 'SQL', 'BdD', 'MongoDB', 'POO', 'Ionic'];

/* Config Quill position image */

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

export class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
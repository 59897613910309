import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, forkJoin, interval, Subject } from 'rxjs';
import { map, catchError, tap, isEmpty, flatMap, concatMap, switchMap, pluck, timeout, mergeMap, delay } from 'rxjs/operators';


const endpointDevice = '/api/sg/device';
const endpointSensor = '/api/sg/sensor';
const endpointType = '/api/sg/type';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export class _Device {
  public static fromJson(json: Object): _Device {
    return new _Device(
      json['_id'],
      json['identifiant'],
      json['type'],
      json['status'],
      json['active'],
      json['localisation'],
      json['name']
    );
  }

  constructor(
    public _id?: String,
    public identifiant: string = '',
    public type: string = '',
    public status?: String,
    public active: Boolean = false,
    public localisation: Array<Object> = [],
    public name?: String
  ){}
}

export class _Sensor {
  public static fromJson(json: Object): _Sensor {
    return new _Sensor(
      json['_id'],
      json['name'],
      json['topic'],
      json['info'],
      json['active'],
      json['maxValue'],
      json['minValue']
    )
  }

  constructor(
    public _id?: String,
    public name: String = '',
    public topic: String = '',
    public info?: String,
    public active: Boolean = false,
    public maxValue: Number = 0,
    public minValue: Number = 0
  ) {}
}

export class _Type {
  public static fromJson(json: Object): _Type{
    return new _Type (
      json['_id'],
      json['name'],
      json['characteristic'],
      json['connexion'],
      json['sensor']
    )
  }

  constructor(
    public _id?: String,
    public name: String = '',
    public characteristic: Array<Object> = [],
    public connexion: String = 'WIFI',
    public sensor: _Sensor[] = []
  ) {}
}


@Injectable({
  providedIn: 'root'
})

export class SmartgnomeService {

  constructor(
    private http: HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /* ----------------------- */
  /* --------DEVICE--------- */
  /* ----------------------- */

  /**
   * Get All device Simple
   * @return {Array}
   */
  getAllDevice() {
    return this.http.get<_Device[]>(`${endpointDevice}`).pipe(
      catchError(this.handleError)
    );
  }


  /* ----------------------- */
  /* --------Sensor--------- */
  /* ----------------------- */

  /**
   * Add new Sensor
   * @param {_Sensor} sensor
   */
  addSensor(sensor: _Sensor): Observable<_Sensor> {
    return this.http.post<_Sensor>(`${endpointSensor}`, sensor, httpOptions).pipe(
      catchError(this.handleError)
    );
  }


  /**
   * Get All Sensor
   * @return {Array}
   */
  getAllSensor() {
    return this.http.get<_Sensor[]>(`${endpointSensor}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Delete a sensor
   */
  deleteSensor(sensorId) {
    return this.http.delete<_Sensor>(`${endpointSensor}/${sensorId}`, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Update a sensor
   */
  updateSensor(sensor: _Sensor) {
    Reflect.deleteProperty(sensor, '__v');
    return this.http.put<_Sensor>(`${endpointSensor}/${sensor._id}`, sensor, httpOptions).pipe(
      catchError(this.handleError)
    );
  }


  /* ----------------------- */
  /* ---------TYPE---------- */
  /* ----------------------- */

  /**
   * Add a Type of Device
   * @param {_Type} type
   */
  addType(type: _Type) {
    return this.http.post<_Type>(`${endpointType}`, type, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get All Type
   * @return {Array}
   */
  getAllType() {
    return this.http.get<_Type[]>(`${endpointType}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get one single type
   * @param {String} typeId 
   */
  getType(typeId: string) {
    return this.http.get<_Type>(`${endpointType}/${typeId}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Update a type
   */
  updateType(type: _Type) {
    return this.http.put<_Type>(`${endpointType}/${type._id}`, type, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Delete a type
   * @param {String} typeId
   */
  deleteType(typeId: string) {
    return this.http.delete<_Type>(`${endpointType}/${typeId}`, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

}

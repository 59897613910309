import { Component, OnInit, ViewChild, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { _Type, _Sensor } from '../../services/smartgnome/smartgnome.service';
import * as _ from 'lodash';
import { _Subject, _Promotion, _Diapo } from '../../services/platformcours/model/_index.model';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { UtilityService } from '../../services/shared/utility.service';


import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
import { QuillConfigGlobal, theme } from '../config';
import { PromotionService } from '../../services/platformcours/services/promotion.service';
import { SubjectService } from '../../services/platformcours/services/subject.service';
import { DiapoService } from '../../services/platformcours/services/diapo.service';
Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-modal-manager',
  templateUrl: './modal-manager.component.html',
  styleUrls: ['./modal-manager.component.scss']
})
export class ModalManagerComponent implements OnInit, OnChanges {
  @ViewChild('modifModalType') modifModalType: ModalDirective;
  @ViewChild('suprModalType') suprModalType: ModalDirective;
  @ViewChild('validSuprSubjectPC') validSuprSubjectPC: ModalDirective;
  @ViewChild('promSupr') promSupr: ModalDirective;
  @ViewChild('profilSupr') profilSupr: ModalDirective;
  @ViewChild('diapoSupr') diapoSupr: ModalDirective;
  @ViewChild('coursSupr') coursSupr: ModalDirective;
  @ViewChild('exoSupr') exoSupr: ModalDirective;
  @ViewChild('corrSupr') corrSupr: ModalDirective;
  @ViewChild('advertModif') advertModif: ModalDirective;
  @ViewChild('advertSupr') advertSupr: ModalDirective;
  @ViewChild('newsModif') newsModif: ModalDirective;
  @ViewChild('newsSupr') newsSupr: ModalDirective;
  @ViewChild('eventModif') eventModif: ModalDirective;
  @ViewChild('eventSupr') eventSupr: ModalDirective;
  @Input() showModal: boolean = false;
  @Input() template: any;
  @Input() data;

  @Output() modalClose = new EventEmitter();
  @Output() justClose = new EventEmitter();

  public myConfig : any = {
    backdrop: 'static'
  };
  public bsConfig;
  public newsExpirationModif;

  public sensors: any = [];
  public chars: any = [];
  public platformCours: any;
  public subjects: Array<_Subject> = [];
  public promotions: Array<_Promotion> = [];
  public language: Array<String> = ['HTML / CSS', 'Javascript', 'PHP', 'Angular', 'Symfony', 'C / C++', 'Java', 'Autres'];
  public theme: any = theme;
  public levelChange: any = 'Débutant';
  public maxDate;

  public advertType:any = ['Alert', 'Danger', 'Info'];
  public validationAdvert = false;

  public typeEvent: Array<string> = ['Cours', 'Examen', 'Divers'];
  public validationEvent: boolean = false;
  public dateEvent: any = null;
  public begin: any = new Date();
  public end:any = new Date();
  public minTime: Date = new Date();
  public maxTime: Date = new Date();

  public EditorModules = QuillConfigGlobal.toolbar;
  public options = {
    height: '400px',
    overflow: 'auto'
  };
  public reset;

  /* -------------- cours ---------------- */
  public diapoList;
  public size;

  // public test;

  constructor(
    bsLocaleService: BsLocaleService,
    private utility: UtilityService,
    private Prom: PromotionService,
    private Sub : SubjectService,
    private Dia : DiapoService
  ) { 
    bsLocaleService.use('fr');
    this.maxDate = new Date();
  }

  ngOnInit() {
    this.Sub.getAllSubject().subscribe(res => {
      this.subjects = res ? res : [];
    });

    this.Prom.getAllProm().subscribe(res => {
      this.promotions = res ? res : [];
    });

    this.Dia.getAllDiapo().subscribe(res => {
      this.diapoList = res ? res : [];
    });

    this.bsConfig = {
      adaptivePosition: true,
      containerClass: 'theme-green',
      locale: 'fr'
    };
    this.minTime.setHours(8);
    this.minTime.setMinutes(0);
    this.maxTime.setHours(18);
    this.maxTime.setMinutes(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.showModal && changes.showModal.currentValue == true ) {
      switch (this.template) {
        case 'typeModif':
          this.modifModalType.show();
          this.sensors = this.data.sensor;
          this.chars = this.data.characteristic;
          break;
        case 'typeSupr':
          this.suprModalType.show();
          this.sensors = this.data.sensor;
          this.chars = this.data.characteristic;
          break;
        case 'subjectSuprPC':
          this.validSuprSubjectPC.show();
          break;
        case 'promSupr':
          this.promSupr.show();
          break;
        case 'profilSupr':
          this.profilSupr.show();
          break;
        case 'diapoSupr':
          this.diapoSupr.show();
          break;
        case 'coursSupr':
          this.size = this.data.diapos.length * 162;
          this.data.diapos = this.changeLength(this.data.diapos, 500);
          this.coursSupr.show();
          break;
          case 'exoSupr':
          this.size = this.data.diapos.length * 162;
          this.data.diapos = this.changeLength(this.data.diapos, 500);
          this.exoSupr.show();
          break;
          case 'corrSupr':
          this.size = this.data.diapos.length * 162;
          this.data.diapos = this.changeLength(this.data.diapos, 500);
          this.corrSupr.show();
          break;
        case 'advertModif':
          this.advertModif.show();
          break;
        case 'advertSupr':
          this.advertSupr.show();
          break;
        case 'newsModif':
          this.newsExpirationModif = this.convertDate(this.data.limit);
          this.newsModif.show();
          break;
        case 'newsSupr':
          this.newsSupr.show();
          break;
        case 'eventModif':
          this.eventModif.show();
          this.initializeDate(this.data.start, this.begin)
          this.initializeDate(this.data.end, this.end)
          this.dateEvent = new Date(this.data.start);
          break;
        case 'eventSupr':
          this.eventSupr.show();
          break;
        default:
          console.log('Error: type de modal inconnu');
          break;
      }
    } 
  }


  onModalClose(template: any, modalType: string) {
    switch (modalType) {
      case 'typeModif':
        template.hide();
        let tmp = new _Type(this.data._id, this.data.name, this.data.characteristic, this.data.connexion, this.data.sensor);
        this.modalClose.emit({mode: 'Modif', type: tmp});
        break;
      case 'typeSupr':
        template.hide();
        this.modalClose.emit({mode: 'Supr', type: this.data});
        break;
      case 'validSuprSubjectPC':
        template.hide();
        this.modalClose.emit({mode: 'subject', type: this.data});
        break;
      case 'promSupr':
        template.hide();
        this.modalClose.emit({mode: 'promSupr', type: this.data});
        break;
      case 'profilSupr':
        template.hide();
        this.modalClose.emit({mode: 'profilSupr', type: this.data});
        break;
      case 'diapoSupr':
        template.hide();
        this.modalClose.emit({mode: 'diapoSupr', type: this.data});
        break;
      case 'coursSupr':
        template.hide();
        this.modalClose.emit({mode: 'coursSupr', type: this.data});
        break;
      case 'exoSupr':
        template.hide();
        this.modalClose.emit({mode: 'exoSupr', type: this.data});
        break;
      case 'corrSupr':
        template.hide();
        this.modalClose.emit({mode: 'corrSupr', type: this.data});
        break;
      case 'advertModif': {
        if(this.data.type && this.data.promotions.length > 0 && this.data.text) {
          this.validationAdvert = false;
          template.hide();
          this.modalClose.emit({mode: 'advertModif', type: this.data});
        } else {
          this.validationAdvert = true;
        }
        break;
      }
      case 'advertSupr':
        template.hide();
        this.modalClose.emit({mode: 'advertSupr', type: this.data});
        break;
      case 'newsModif': {
        template.hide();
        this.data.limit = typeof this.newsExpirationModif != 'string' ? Date.parse(this.newsExpirationModif) : this.data.limit;
        this.modalClose.emit({mode: 'newsModif', type: this.data});
        break;
      }
      case 'newsSupr':
        template.hide();
        this.modalClose.emit({mode: 'newsSupr', type: this.data});
        break;
      case 'eventModif': {
        if(this.data.title && this.data.promotion && this.data.type && this.data.type && this.dateEvent) {
          this.validationAdvert = false;
          this.data.start = this.convertDateWithHour(this.dateEvent, this.begin);
          this.data.end  = this.convertDateWithHour(this.dateEvent, this.end);
          template.hide();
          this.modalClose.emit({mode: 'eventModif', type: this.data});
        } else {
          this.validationAdvert = true;
        }
        break;
      }
      case 'eventSupr':
        template.hide();
        this.modalClose.emit({mode: 'eventSupr', type: this.data});
        break;
      default:
        console.log('Error, type de modal inconnu');
        break;
    }
  }

  onCloseAlone(template: any, modalType: string) {
    switch (modalType) {
      case 'typeModif':
        template.hide();
        this.justClose.emit({id: this.data._id, mode: 'Modif'});
        break;
      case 'typeSupr':
        template.hide();
        this.justClose.emit({id: null, mode: 'Supr'});
        break;
      case 'validSuprSubjectPC':
        template.hide();
        this.justClose.emit({mode: 'validSuprSubjectPc'});
        break;
      case 'promSupr':
        template.hide();
        this.justClose.emit({mode: 'promSupr'});
        break;
      case 'profilSupr':
        template.hide();
        this.justClose.emit({mode: 'profilSupr'});
        break;
      case 'diapoSupr':
        template.hide();
        this.justClose.emit({mode: 'diapoSupr'});
        break;
      case 'coursSupr':
        template.hide();
        this.justClose.emit({mode: 'coursSupr'});
        break;
      case 'exoSupr':
        template.hide();
        this.justClose.emit({mode: 'exoSupr'});
        break;
      case 'corrSupr':
        template.hide();
        this.justClose.emit({mode: 'corrSupr'});
        break;
      case 'advertModif':
        template.hide();
        this.justClose.emit({mode: 'advertModif'});
        break;
      case 'advertSupr':
        template.hide();
        this.justClose.emit({mode: 'advertSupr'});
        break;
      case 'newsModif':
        template.hide();
        this.justClose.emit({mode: 'newsModif'});
        break;
      case 'newsSupr':
        template.hide();
        this.justClose.emit({mode: 'newsSupr'});
        break;
      case 'eventModif':
        template.hide();
        this.justClose.emit({mode: 'eventModif'});
        break;
      case 'eventSupr':
        template.hide();
        this.justClose.emit({mode: 'eventSupr'});
        break;
      default:
        break;
    }
  }

  onSensorChange(event: any) {
    this.data.sensor = event;
  }
  
  onCharacChange(event:any) {
    this.data.characteristic = event;
  }

  onCheck(val: any) {
    let index = this.data.already_known.indexOf(val);

    if(index != -1 ) {
      this.data.already_known.splice(index, 1);
    } else {
      this.data.already_known.push(val);
    }
  }

  onCheckTags(val: any) {
    let index = this.data.tags.indexOf(val);

    if(index != -1) {
      this.data.tags.splice(index, 1);
    } else {
      this.data.tags.push(val);
    }
  }

  isCheckedTags(val: any) {
    let index = this.data.tags.indexOf(val);
    return index != -1;
  }

  onCheckProm(val: string) {
    let index = this.data.promotions.indexOf(val);
    if(index != - 1) {
      this.data.promotions.splice(index, 1);
    } else {
      this.data.promotions.push(val);
    }
  }

  onLevelChange() {
    switch (this.data.level) {
      case 0:
        this.levelChange = 'Débutant';
        break;
      case 1 :
        this.levelChange = 'Habitué';
        break;
      case 2:
        this.levelChange = 'Cours déjà lu';
        break;
      case 3:
        this.levelChange = 'Codeur très débutant';
        break;
      case 4:
        this.levelChange = 'Codeur initié';
        break;
      case 5:
        this.levelChange = 'Codeur en perfectionnement';
        break;
      default:
        break;
    }
  }

  // Image Upload in Quill
  ImgUploadQuill(event: any) {
    return this.utility.EditorCreated(event);
  }

  onContentDiapoChange(event: any) {
    this.data.content = event.html;
  }

   /* ------------------- Cours -------------------- */
  addAllProm(comp: string) {
    switch (comp) {
      case 'Advert': {
        if(this.data.promotions.length != this.promotions.length) {
          this.data.promotions = this.promotions.map((x:any) => {
            return x._id;
          })
        } else {
          this.data.promotions = [];
        }
        break;
      }
      default:
        console.log('Unknown component used for promotions add');
        break;
    }
  }

  convertDate(dte: any) {
    let tmp = new Date(dte);
    return ('0' + tmp.getDate()).slice(-2) + '/'
            + ('0' + (tmp.getMonth()+1)).slice(-2) + '/'
            + tmp.getFullYear();
  }

  initializeDate(dte: any, time: any) {
    let tmp = new Date(dte);
    time.setHours(tmp.getHours());
    time.setMinutes(tmp.getMinutes());
  }

  convertDateWithHour(day, time) {
    let timeInMilliseconds = ((time.getHours() * 3600) + (time.getMinutes() * 60)) * 1000;
    let dateToSet = new Date(day);
    console.log(dateToSet)
    dateToSet.setHours(0);
    dateToSet.setMinutes(0);
    let dateToParse = dateToSet.toDateString();
    return Date.parse(dateToParse) + timeInMilliseconds;
  }

  changeLength(data: Array<_Diapo>, size: number) : Array<any> {
    data.forEach(elt => {
      elt.content = elt.content.substring(0, size);
    });
    return data;
  }
}



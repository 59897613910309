import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filterProm'
})
export class FilterPromPipe implements PipeTransform {

  transform(value: string, list: any[], filter: string): any {
    if(!value) return null;
    let data = _.find(list, (o)=> {
      return o._id == value
    });

    if(!filter) return data;

    return data[filter];
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() user: any = {};

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
  }

  isLogged(): any {
    let logged: Boolean = false;
    if(this.user) logged = true;
    return logged;
  }

  isAdmin(): any {
    let adm: Boolean = false;
    if(this.user.roles[0] == 'admin') adm = true;
    return adm;
  }

  logout(): void {
    this.authService.signOut();
    this.router.navigate(['/']);
  }

  navigate(link): void {
    this.router.navigate([link]);
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDiapo'
})
export class FilterDiapoPipe implements PipeTransform {

  transform(list: any[], filters: string[]): any {
    // console.log(list)
    // console.log(filters)
    // let props = ['position', 'title', 'createdOn']
    if(!list) return [];
    if(!filters[0] && !filters[1] && !filters[2]) return list;

    let filteredItems = [];
    let minuscules = filters[1] ? filters[1].toLowerCase() : null;
    let situation = null;
    let timestamp = filters[2] ? new Date(filters[2]).getTime() : null;


    if(filters[0] && !filters[1] && !filters[2]) {
      situation = '100'; // position seul
    } else if(filters[0] && filters[1] && !filters[2]) {
      situation = '110'; //position + titre
    } else if(filters[0] && !filters[1] && filters[2]) {
      situation = '101'; // position + date
    } else if(!filters[0] && filters[1] && !filters[2]) {
      situation = '010'; // titre seul
    } else if(!filters[0] && filters[1] && filters[2]) {
      situation = '011'; // titre + date
    } else if (!filters[0] && !filters[1] && filters[2]) {
      situation = '001'; // date seul
    } else if(filters[0] && filters[1] && filters[2]) {
      situation = '111'; // position + titre + date
    } else {
      situation = null;
    }

    switch (situation) {
      case '001':
      { // date seul
        list.forEach(item => {
          if(item['createdOn'] >= timestamp) filteredItems.push(item);
        });
        break;
      }
      case '100':
      { // position seul
        list.forEach(item => {
          if(item['position'] == filters[0]) filteredItems.push(item);
        });
        break;
      }
      case '010':
      { // titre seul
        list.forEach(item => {
          if(item['title'].toLowerCase().includes(minuscules)) filteredItems.push(item);
        });
        break;
      }
      case '110':
      { // position + titre
        list.forEach(item => {
          if(item['position'] == filters[0] && item['title'].toLowerCase().includes(minuscules)) filteredItems.push(item);
        });
        break;
      }
      case '101':
      { // position + date
        list.forEach(item => {
          if(item['position'] == filters[0] && item['createdOn'] >= timestamp) filteredItems.push(item);
        });
        break;
      }
      case '011':
      { // titre + date
        list.forEach(item => {
          if(item['title'].toLowerCase().includes(minuscules) && item['createdOn'] >= timestamp) filteredItems.push(item);
        });
        break;
      }
      case '111':
      { // date + titre + position
        list.forEach(item => {
          if(item['position'] == filters[0] && item['title'].toLowerCase().includes(minuscules) && item['createdOn'] >= timestamp) filteredItems.push(item);
        });
        break;
      }

      default:
        console.log('Unknowk filter');
        return list;
    }

    return filteredItems;
  }

}

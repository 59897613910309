import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import { AlertConfig } from "ngx-bootstrap/alert";

export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: 'success' });
}


export const QuillConfigGlobal: any = {
  toolbar : {
    syntax: true,
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [{ 'script': 'super' }, { 'script': 'sub' }],
      [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
      [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
      [ 'direction', { 'align': [] }],
      [ 'link', 'image']
    ],
    imageResize: true
  },
  style : {
    height: '600px',
    overflow: 'auto'
  }
}


export const theme: any = ['HTML', 'CSS', 'SCSS', 'Javascript', 'Angular', 'JQuery', 'CMS', 'Wordpress', 'Programmation', 'OS', 'PHP', 'NodeJS', 'VueJS', 'Virtualisation', 'SQL', 'BdD', 'MongoDB', 'POO', 'Ionic'];

/* Config Quill position image */

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

export class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public user;

  public pwd;
  public viewPwd: boolean = false;

  public newPassword;
  public viewNewPassword: boolean = false;

  public verifyNewPassword;
  public viewVerifyNewPassword: boolean = false;

  public err: boolean = false;
  public errSpe: boolean = false;
  public errMsg;
  public alertDismiss = [];

  constructor(
    private Auth: AuthService
  ) {}

  public ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('StoredUser'))
  }

  onShowPassword(name: any) {
    switch (name) {
      case 'pwd':
        this.viewPwd = !this.viewPwd;
        break;
      case 'new':
        this.viewNewPassword = !this.viewNewPassword;
        break;
      case 'verify':
        this.viewVerifyNewPassword = !this.viewVerifyNewPassword;
        break;
      default:
        console.log('Unknown value of show password');
        break;
    }
  }

  onChangePassword() {
    if(this.newPassword == this.verifyNewPassword) {
      this.err = false;
      this.Auth.modifPassword(this.pwd, this.newPassword, this.user._id).subscribe(res => {
        if(res) {
          this.errSpe = false;
          this.showDismiss();
        } else {
          this.errSpe = true;
          this.errMsg = 'Mot de passe incorrect';
        }
      })
    } else {
      this.err = true;
      this.errMsg = 'Les mots de passes ne correspondent pas'!
    }
  }

  showDismiss() {
    this.alertDismiss.push({
      type: 'success',
      msgBold: `Modification enregistrée`,
      timeout: 3000
    });
    this.pwd = null;
    this.newPassword = null;
    this.verifyNewPassword = null;
  }


}



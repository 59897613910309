import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { HomeComponent } from './home/home.component';

import { P404Component } from './error/404.component';
import { P500Component } from './error/500.component';
import { NotauthorizedComponent } from './error/notauthorized.component';

const routes: Routes = [
  {
  path: '',
  redirectTo: '/home',
  pathMatch: 'full'
},
{
  path: 'home',
  component: HomeComponent,
  pathMatch: 'prefix',
}, {
  path: 'auth',
  loadChildren: 'app/auth/auth.module#AuthModule'
}, {
  path: 'admin',
  loadChildren: 'app/admin/admin.module#AdminModule'
}, {
  path: 'notauthorized',
  component: NotauthorizedComponent
}, {
  path: 'smartgnome',
  loadChildren: 'app/smartgnome/smartgnome.module#SmartGnomeModule'
}, {
  path: 'platform',
  loadChildren: 'app/platformcours/platformcours.module#PlatformcoursModule'
}, {
  path: '**',
  component: P404Component,
  data: {
    title: 'Page 404'
  }
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
  declarations: []
})

export class AppRoutingModule {}

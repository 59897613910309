import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { PlatformcoursService } from '../services/platformcours/platformcours.service';
import { SmartgnomeService } from '../services/smartgnome/smartgnome.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public showHome: Boolean = true;
  public cours;
  private subscription: Subscription;
  public user: any = null;

  constructor(
    private sg: SmartgnomeService,
    private pc: PlatformcoursService,
    private Auth: AuthService
  ) { }

  ngOnInit() {
    this.subscription = this.Auth.getUserBehavior().subscribe(res => {
      this.user = res;
      if(res) {
        this.pc.getInfo().subscribe(res => {
          this.cours = res ? res : null;
          // console.log(this.cours)
        });
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

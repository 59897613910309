import { Component, OnInit, OnChanges, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';

export class _Char {
  public static fromJson(json: Object):_Char {
    return new _Char(
      json['label'],
      json['details']
    );
  }
  constructor(
    public label: String = '',
    public details: String = ''
  ) {}
}

@Component({
  selector: 'app-characteristic-list',
  templateUrl: './characteristic-list.component.html',
  styleUrls: ['./characteristic-list.component.scss']
})


export class CharacteristicListComponent implements OnInit {
  @Input() template: any;
  @Input() reset : any;
  @Input() dataInit: any;
  @Output() newCharacteristicEvent = new EventEmitter();

  public chars: _Char[] = []
  public newChar = new _Char();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.reset && changes.reset.currentValue == true) {
      this.chars = [];
      setTimeout(() => {
        this.newCharacteristicEvent.emit(this.chars);
      }, 1000);
    }

    if(changes.dataInit && changes.dataInit.currentValue.length > 0) {
      this.chars = this.dataInit;
    }
  }

  doReset() {
    this.newChar = new _Char();
  }

  addChar() {
    this.chars.push(this.newChar);
    this.newChar = new _Char();
    this.newCharacteristicEvent.emit(this.chars);
  }

  trash(index: any ) {
    this.chars.splice(index, 1);
  }

}

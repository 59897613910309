import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { _Diapo } from '../model/_diapo.model';

const endpointSupport = '/api/pc/support';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DiapoService {

  constructor(
    private http : HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Get all diapos
   * @return {Array}
   */
  getAllDiapo() {
    return this.http.get<_Diapo[]>(`${endpointSupport}/diapo`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get a diapo
   * @param {String} id - diapo's id
   * @return {_Diapo}
   */
  getDiapo(id: String) {
    return this.http.get<_Diapo>(`${endpointSupport}/diapo/${id}`).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Add diapo
   * @param {_Diapo} diapo
   */
  addDiapo(diapo: _Diapo) {
    return this.http.post<_Diapo>(`${endpointSupport}/diapo`, diapo, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Update profil
   * @param {_Diapo} diapo
   */
  updateDiapo(diapo: _Diapo) {
    Reflect.deleteProperty(diapo, '__v');
    return this.http.put<_Diapo>(`${endpointSupport}/diapo/${diapo._id}`, diapo, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Delete profil
   * @param {String} id - profils Id
   */
  deleteDiapo(id: String) {
    return this.http.delete<_Diapo>(`${endpointSupport}/diapo/${id}`, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
